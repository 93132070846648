import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p className="barely-loud">These last two sections probably sound oddly familiar. None of the macro forces have changed.</p>
    <dl>
      <p>
        <dt>Microsoft's monopoly being the indirect adversary of web standards?</dt>
        <dd>All the tech giants are once again in the situation where they are the marketplace and also the product.</dd>
      </p>
      <p>
        <dt>Apple playing innocent while quietly throwing developers under the bus and making decisions based purely on their own desires for control and dominance?</dt>
        <dd>See Epic v. Apple.</dd>
      </p>
      <p>
        <dt>Developing for the web being a minefield of inconsistencies?</dt>
        <dd>Well, this has actually gotten a lot better, but the pendulum might be swinging too far in the other direction. We're continuing to sink into a Chromium monoculture.</dd>
      </p>
    </dl>
    <p>{`In the same way that democracy doesn't work unless the people make it work, we can't let the open web become the PR-friendly branding of a tech oligopoly's grander, very much not open, motives.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      